import React, { useEffect, useState } from "react"
import { Configure, InstantSearch } from "react-instantsearch-core"
import {
  connectHits,
  connectRefinementList,
  // SearchBox,
} from "react-instantsearch-dom"
import Hit from "../components/search/Hit"
import Seo from "../components/seo"
// import { Link } from "gatsby"
import qs from "qs"
import Layout from "../components/new-design/Layout"
// import BottomBar from "../components/BottomBar"
import {
  GoogleMapsLoader,
  GeoSearch,
  Marker,
} from "react-instantsearch-dom-maps"
import { getCustomSearchClient, getSearchClient } from "../utils/algolia"
import { Fragment } from "react"
import { getSearchDates } from "../utils/propertyUtil"
import { addDays, format } from "date-fns"
import { navigate } from "gatsby"
import HeadSection from "../components/new-design/HeadSection"
import Container from "../components/new-design/Container"

export default function Page({ location, data, history }) {
  const [start, setStart] = useState()
  const [end, setEnd] = useState()
  const [adults, setAdults] = useState()
  const [children, setChildren] = useState()
  const [doSearch, setDoSearch] = useState(true)
  const [filters, setFilters] = useState()

  const getFilter = (start, end, guests) => {
    const dates = getSearchDates(start, end)

    const availabilityString = dates
      .map(d => ` AND availability:${d}`)
      .join("")
      .replace("AND ", "")

    const newFilter = `(${availabilityString}) AND guests >= ${guests}`
    // const newFilter = `(${availabilityString})`
    return newFilter
  }

  useEffect(() => {
    const params = qs.parse(
      typeof window !== "undefined"
        ? window.location.search.substring(1)
        : `start=2022-08-06&end=2022-08-13&adults=4&children=2`
    )

    let { start, end, adults, children } = params

    if (!start) start = format(addDays(new Date(), 14), "yyyy-MM-dd")
    if (!end) end = format(addDays(new Date(), 21), "yyyy-MM-dd")

    if (!adults) adults = 4
    else adults = parseInt(adults)

    if (!children) children = 2
    else children = parseInt(children)

    setStart(start)
    setEnd(end)
    setAdults(adults)
    setChildren(children)

    const newFilter = getFilter(start, end, adults + children)

    setFilters(newFilter)

    // navigate("/villas?" + qs.stringify({ start, end, adults, children }))

    // setDoSearch(false)
  }, [])

  // const searchClient = getSearchClient(true)

  const searchClient = getCustomSearchClient(doSearch, start, end)

  const handleSearch = () => {
    const newFilter = getFilter(start, end, adults + children)
    setFilters(newFilter)
  }
  return (
    <Layout>
      <div>
        <HeadSection title="Search Properties" />
        <Seo title="Search" pathname={location.pathname} />
        {filters && (
          <InstantSearch searchClient={searchClient} indexName="properties">
            <Configure filters={filters} />
            <Container>
              <div className="px-4 md:px-6 py-4 md:py-6 bg-white rounded-xl lg:rounded-full relative -top-16">
                <div className="flex flex-col lg:flex-row gap-4">
                  {/* <SearchBox /> */}

                  <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 w-full lg:w-5/6">
                    <Input
                      placeholder="start"
                      label="Check in date"
                      name="check-in"
                      value={start}
                      onChange={event => setStart(event.target.value)}
                      type="date"
                      className="px-4 py-2 h-11 rounded outline-none focus:outline-none w-full"
                    />
                    <Input
                      placeholder="end"
                      label="Check out date"
                      name="check-out"
                      value={end}
                      onChange={event => setEnd(event.target.value)}
                      type="date"
                      className="px-4 py-2 h-11 rounded outline-none focus:outline-none w-full"
                    />
                    <Select
                      placeholder="Adults"
                      label="Adults"
                      name="Adults"
                      value={adults}
                      onChange={event =>
                        setAdults(parseInt(event.target.value))
                      }
                      options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
                      className="px-4 py-2 h-11 rounded outline-none focus:outline-none w-full"
                    />
                    <Select
                      placeholder="Children"
                      label="Children"
                      name="Children"
                      value={children}
                      onChange={event =>
                        setChildren(parseInt(event.target.value))
                      }
                      options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
                      className="px-4 py-2 h-11 rounded outline-none focus:outline-none w-full"
                    />
                  </div>
                  <div className="w-full lg:w-1/6">
                    <button
                      className="text-white bg-[#223C4F] mb-2 px-6 py-2 rounded-full outline-none focus:outline-none w-full h-auto lg:h-full"
                      onClick={handleSearch}
                    >
                      search
                    </button>
                  </div>
                </div>
              </div>
            </Container>
            <Container>
              <div className="flex flex-col lg:flex-row items-start gap-3 lg:gap-0">
                <div className="w-full lg:w-2/6 md:col-span-3  bg-stone-50 rounded-xl">
                  <h2 className="font-semibold text-xl  p-4 border-b">
                    Advanced Filter
                  </h2>
                  <CustomRefinementList
                    attribute="propertyFeatures.name"
                    className="tracking-widest text-h-2"
                    listName="Features"
                    showMore
                    showMoreLimit={100}
                    operator="and"
                  />
                  <div className="p-2 border-t">
                    <button
                      onClick={() => navigate("/villas")}
                      className="w-full py-2 rounded-full bg-stone-200 text-sm text-stone-600"
                    >
                      Reset Filter
                    </button>
                  </div>
                </div>
                <div className="w-full lg:w-5/6 px-0 lg:px-4">
                  <CustomHits
                    start={start}
                    end={end}
                    adults={adults}
                    children={children}
                  />
                </div>
              </div>
              <div className="rounded-xl overflow-hidden aspect-video pt-4">
                <Map />
              </div>
            </Container>
            {/* <BottomBar nextStep="/booking/choose-villa" /> */}
          </InstantSearch>
        )}
      </div>
    </Layout>
  )
}

// const Hit = ({ hit }) => <div className="bg-gray-300 p-12">{hit.name}</div>

const Input = ({
  label,
  name,
  onChange,
  type,
  className,
  value,
  placeholder,
}) => {
  return (
    <div className="flex flex-col">
      <label htmlFor={name} className="text-xs pl-0 md:pl-3">
        {label}
      </label>
      <input
        id={name}
        className={`border-stone-300 ${className}`}
        type={type}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
      />
    </div>
  )
}

const Select = ({
  label,
  placeholder,
  className,
  options,
  onChange,
  value,
}) => {
  return (
    <div className="flex flex-col">
      <label className="text-xs pl-0 md:pl-3">{label}</label>
      <select
        placeholder={placeholder}
        className={`border-stone-300 ${className}`}
        onChange={onChange}
        value={value}
      >
        {options.map((opt, key) => {
          return (
            <option value={opt} key={key}>
              {opt}
            </option>
          )
        })}
      </select>
    </div>
  )
}
const RefinementList = ({ items, refine, listName }) => (
  <div className="p-4">
    {/* <h2 className="font-semibold text-lg pb-6">{listName}</h2> */}
    <ul className="overflow-y-auto grid gap-1 pt-3">
      {items.length === 0 && (
        <div className="flex justify-center">
          <svg
            className="animate-spin h-6 w-6 text-black"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      )}
      {items.map(item => (
        <li className="text-sm" key={item.label}>
          <a
            href="#/"
            className={
              item.isRefined
                ? "font-bold flex justify-between items-center"
                : " flex items-center"
            }
            onClick={event => {
              event.preventDefault()
              refine(item.value)
            }}
          >
            <span>
              {item.label} ({item.count})
            </span>
            {item.isRefined && (
              <span className="h-6 w-6 flex items-center justify-center bg-black text-white text-xs font-bold rounded-full">
                X
              </span>
            )}
          </a>
        </li>
      ))}
    </ul>
  </div>
)

const CustomRefinementList = connectRefinementList(RefinementList)

const Hits = ({ hits, ...params }) => {
  return (
    <>
      {hits.length > 0 ? (
        <Fragment />
      ) : (
        // <h3 className="text-xl font-light">{hits.length} results founds</h3>
        <div className="flex justify-center">
          <svg
            className="animate-spin h-12 w-12 text-black"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      )}
      <ol className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-4">
        {hits.map(hit => (
          <li key={hit.objectID}>
            {hit ? <Hit hit={hit} {...params} /> : <div>Loading</div>}
          </li>
        ))}
      </ol>
    </>
  )
}

const CustomHits = connectHits(Hits)

const Map = () => {
  return (
    <GoogleMapsLoader apiKey="AIzaSyCvYSJlYbYO7JNCWoCk7aEkI2pMBkWMPsw">
      {google => (
        <GeoSearch
          google={google}
          // mapTypeId={google.maps.MapTypeId.SATELLITE}
          zoom={11}
          enableRefine={true}
          maxZoom={12}
          // enableRefineOnMapMove={false}
        >
          {({ hits }) => {
            return (
              <>
                {hits.map((hit, i) => (
                  <div key={`map-hit-${i}`}>
                    <Marker key={hit.objectID} hit={hit} />
                  </div>
                ))}
              </>
            )
          }}
        </GeoSearch>
      )}
    </GoogleMapsLoader>
  )
}
